import { Loader, Select } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { SWRHook } from 'swr'

import { useFetch } from '@/lib/hooks'
import { stringCompare, swrCallbackMiddleware } from '@/lib/utils'
import { City as CityType } from '@/types'

type Props = {
  selected?: CityType | null
  onChange?: (newValue: CityType) => void
  inputProps?: any
}

export default function CityAutocomplete({ selected, onChange, inputProps }: Props) {
  // Hooks
  const router = useRouter()
  const { site: siteSlug } = router.query || {}

  // States
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 500)

  // Fetch callback
  const [dataResults, setDataResults] = useState<any[]>([])
  const dataCallback = (data: any) => {
    const results = [...data?.results, ...dataResults].filter(
      (city, index, array) => index === array.findIndex(item => item?.uid === city?.uid)
    )
    setDataResults(results)
  }

  // Fetch
  const { data, error } = useFetch(
    [siteSlug ? `/${siteSlug}/cities/` : null, { page_size: 20, search: debouncedSearch }],
    { use: [(useSWRNext: SWRHook) => swrCallbackMiddleware(dataCallback)(useSWRNext)] }
  )

  // Constants
  const loading = !data && !error
  const formattedData = Array.isArray(dataResults)
    ? [...(selected ? [{ ...selected }] : []), ...dataResults]
        .map((city: any) => ({
          ...city,
          label: `${city?.name} - ${city?.state?.code}`,
          value: city?.uid
        }))
        .filter((city, index, array) => index === array.findIndex(item => item?.uid === city?.uid))
        .sort((a, b) => a?.name?.localeCompare(b?.name))
    : []

  // Actions
  const handleChange = (value: string) =>
    onChange?.(formattedData.find(city => city?.uid === value))

  return (
    <Select
      label="Cidade/Estado"
      data={formattedData}
      clearable
      searchable
      nothingFound={loading ? 'Buscando...' : 'Nenhum resultado'}
      searchValue={search}
      onSearchChange={setSearch}
      filter={(_, item) => stringCompare(item?.name, search)}
      rightSection={loading && <Loader size="xs" />}
      {...inputProps}
      value={selected?.uid || null}
      onChange={handleChange}
    />
  )
}
