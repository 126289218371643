import { Box, Loader, Select } from '@mantine/core'
import { useRouter } from 'next/router'
import React, { forwardRef, useState } from 'react'
import { SWRHook } from 'swr'

import { PictureCard } from '@/components/surfaces'
import { useFetch } from '@/lib/hooks'
import { stringCompare, swrCallbackMiddleware } from '@/lib/utils'
import { StudentSiteProfile as StudentSiteProfileType } from '@/types'

type Props = {
  selected?: StudentSiteProfileType | null
  onChange?: (newValue: StudentSiteProfileType) => void
  inputProps?: any
}

export default function StudentAutocomplete({ selected, onChange, inputProps }: Props) {
  // Hooks
  const router = useRouter()
  const { site: siteSlug } = router.query || {}

  // States
  const [search, setSearch] = useState('')

  // Fetch callback
  const [dataResults, setDataResults] = useState<any[]>([])
  const dataCallback = (data: any) => {
    const results = [...data?.results, ...dataResults].filter(
      (student, index, array) => index === array.findIndex(item => item?.uid === student?.uid)
    )
    setDataResults(results)
  }

  // Fetch
  const { data, error } = useFetch(
    [siteSlug ? `/${siteSlug}/students/` : null, { page_size: 20, search }],
    { use: [(useSWRNext: SWRHook) => swrCallbackMiddleware(dataCallback)(useSWRNext)] }
  )

  // Constants
  const loading = !data && !error
  const formattedData = Array.isArray(dataResults)
    ? [...(selected ? [{ ...selected }] : []), ...dataResults]
        .map((student: any) => ({
          ...student,
          label: `${student?.firstName} ${student?.lastName}`,
          value: student?.uid
        }))
        .filter(
          (student, index, array) => index === array.findIndex(item => item?.uid === student?.uid)
        )
        .sort((a, b) => b?.isResponsible - a?.isResponsible)
    : []

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, any>(
    (
      {
        uid,
        firstName,
        lastName,
        birthdate,
        picture,
        isResponsible,
        isActive,
        dateCreated,
        dateUpdated,
        totalOrders,
        hasOrders,
        providers,
        responsible,
        basket,
        label,
        ...others
      }: any,
      ref
    ) => (
      <Box p="xs" ref={ref} {...others}>
        <PictureCard
          title={label}
          subtitle={isResponsible ? 'Responsável' : 'Aluno'}
          subtitlePosition="before"
          picture={picture}
        />
      </Box>
    )
  )

  // Actions
  const handleChange = (value: string) =>
    onChange?.(formattedData.find(student => student?.uid === value))

  return (
    <Select
      placeholder="Selecionar aluno"
      itemComponent={SelectItem}
      data={formattedData}
      clearable
      searchable
      nothingFound={loading ? 'Buscando...' : 'Nenhum resultado'}
      searchValue={search}
      onSearchChange={setSearch}
      filter={(_, item) => stringCompare(`${item?.firstName} ${item?.lastName}`, search)}
      rightSection={loading && <Loader size="xs" />}
      {...inputProps}
      value={selected?.uid || null}
      onChange={handleChange}
      styles={theme => ({
        item: {
          '&[data-selected]': {
            '&, &:hover': { backgroundColor: theme.colors.blue[1], color: theme.white }
          }
        }
      })}
    />
  )
}
