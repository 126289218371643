import { createStyles, Paper, Text, Title, UnstyledButton } from '@mantine/core'
import { ReactNode } from 'react'

const useStyles = createStyles(theme => ({
  panel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing.lg,
    [theme.fn.smallerThan('xs')]: {
      display: 'grid',
      justifyContent: 'center',
      justifyItems: 'center',
      gap: theme.spacing.xs
    }
  },
  content: { flexGrow: 1, [theme.fn.smallerThan('xs')]: { textAlign: 'center' } }
}))

interface Props {
  title: string
  description?: string | ReactNode
  handleClick?: () => void
  actions?: ReactNode[]
}

export default function Panel({ title, description, handleClick, actions }: Props) {
  // Hooks
  const { classes } = useStyles()
  return (
    <Paper p="md" withBorder className={classes.panel}>
      <UnstyledButton
        className={classes.content}
        onClick={() => (handleClick ? handleClick() : {})}>
        <Title order={5}>{title}</Title>
        {description && <Text size="sm">{description}</Text>}
      </UnstyledButton>
      {actions && actions.map(action => action)}
    </Paper>
  )
}
