import {
  Anchor,
  Box,
  Chip,
  createStyles,
  Group,
  Loader,
  MultiSelect,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme
} from '@mantine/core'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { SWRHook } from 'swr'

import * as DisplayStatus from '@/components/commons/DisplayStatus'
import { PictureCard } from '@/components/surfaces'
import { useFetch } from '@/lib/hooks'
import { stringCompare, swrCallbackMiddleware } from '@/lib/utils'
import { Order as OrderType } from '@/types'

interface Props {
  selectedOrder?: any | null
  sellerSlug?: string
  changeable?: boolean
  required?: boolean
  onChange?: (newValue: OrderType | null) => void
  inputProps?: any
  placeholder?: string
}

const useStyles = createStyles(theme => ({
  option: {
    ':hover': {
      backgroundColor: theme.colors.gray[2]
    }
  }
}))

export default function SupportOrderAutocomplete({
  onChange,
  selectedOrder,
  sellerSlug,
  changeable = true,
  required = false,
  inputProps,
  placeholder = 'Buscar Pedido'
}: Props) {
  // Hooks
  const router = useRouter()
  const theme = useMantineTheme()
  const { classes } = useStyles()

  // Constants
  const { site: siteSlug } = router.query || {}

  // States
  const [search, setSearch] = useState('')

  // Fetch callback
  const [dataResults, setDataResults] = useState<OrderType[]>([])
  const dataCallback = (data: any) => {
    const results = [...data?.results, ...dataResults].filter(
      (order, index, array) => index === array.findIndex(item => item?.uid === order?.uid)
    )
    setDataResults(results)
  }

  const { data, error } = useFetch(
    [
      siteSlug ? `/${siteSlug}/orders/` : null,
      {
        search,
        seller: sellerSlug || null
      }
    ],
    { use: [(useSWRNext: SWRHook) => swrCallbackMiddleware(dataCallback)(useSWRNext)] }
  )

  // Constants
  const loading = !data && !error
  const formattedData = Array.isArray(dataResults)
    ? [...dataResults]
        .map((order: any) => ({
          ...order,
          label: `${order?.orderNumber}`,
          value: order?.uid
        }))
        .filter(
          (order, index, array) => index === array.findIndex(item => item?.uid === order?.uid)
        )
        .sort((a, b) => a?.label?.localeCompare(b?.label))
    : []

  // Actions
  const handleChange = (value: string) =>
    onChange?.(formattedData.find(order => order?.uid === value))

  if (selectedOrder) {
    return (
      <Box p={2} mb="sm">
        <Text mb="sm">
          Pedido: <strong>{selectedOrder?.orderNumber}</strong>{' '}
          {changeable && (
            <Anchor
              size="sm"
              color={theme.colors[theme.primaryColor][7]}
              onClick={() => onChange?.(null)}>
              (Alterar)
            </Anchor>
          )}
        </Text>
        <Group spacing="sm" position="apart">
          <PictureCard
            title={`${selectedOrder?.customer?.firstName} ${selectedOrder?.customer?.lastName}`}
            subtitle={selectedOrder?.customer?.email}
            picture={selectedOrder?.customer?.picture}
          />
          <PictureCard
            picture={selectedOrder?.seller?.logo}
            title={`${selectedOrder?.seller?.displayName}`}
            subtitle="Vendedor"
            subtitlePosition="before"
          />
        </Group>
      </Box>
    )
  }

  return (
    <Stack>
      <MultiSelect
        label="Selecione o pedido relacionado ao atendimento:"
        placeholder={placeholder}
        data={formattedData}
        clearable
        searchable
        nothingFound={loading ? 'Buscando...' : 'Nenhum resultado'}
        searchValue={search}
        onSearchChange={setSearch}
        filter={(value, selected, item) => stringCompare(item?.label, search)}
        rightSection={loading && <Loader size="xs" />}
        onChange={handleChange}
        value={selectedOrder}
        required={required}
        itemComponent={(option: any) => (
          <UnstyledButton
            p={5}
            onClick={() => handleChange(option.value)}
            className={classes.option}>
            <Text size="sm">
              <DisplayStatus.Order
                type="order"
                orderNumber={option?.orderNumber}
                statusDisplay={option?.statusDisplay}
                status={option?.status}
                isReverted={option?.isReverted}
                {...option?.contract}
                {...option?.tuition}
              />
            </Text>
          </UnstyledButton>
        )}
        valueComponent={(value, getTagProps) =>
          value?.map((option: any, index: number) => (
            <Chip
              key={index}
              size="small"
              label={option?.orderNumber || ''}
              {...getTagProps({ index })}
            />
          ))
        }
        {...inputProps}
      />
    </Stack>
  )
}
