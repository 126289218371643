import { Box, createStyles, Group, MantineNumberSize, Text, ThemeIcon, Title } from '@mantine/core'
import { ReactNode } from 'react'

interface Props {
  title?: string
  description?: string
  descriptionSize?: MantineNumberSize
  icon?: ReactNode
}

const useStyles = createStyles(theme => ({
  icon: { [theme.fn.smallerThan('xs')]: { display: 'none' } }
}))

export default function Heading({ title, description = '', descriptionSize = 'md', icon }: Props) {
  const { classes } = useStyles()
  return (
    <Group mb="xl" noWrap>
      <ThemeIcon size={72} radius="md" mr="sm" hidden={!icon} className={classes.icon}>
        {icon}
      </ThemeIcon>
      <Box>
        <Title order={3}>{title}</Title>
        <Text
          size={descriptionSize}
          hidden={!description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Box>
    </Group>
  )
}
