import { ActionIcon, Badge } from '@mantine/core'
import { IconAlertCircle, IconCircleCheck, IconCircleX, IconInbox, IconInfoSquare, IconReport } from '@tabler/icons'
import React from 'react'

import { TicketStatusType } from '@/types/ticket'

interface Props {
  status: TicketStatusType
  statusDisplay: string
}

export default function Ticket({ status, statusDisplay }: Props) {
  // Constants
  const TICKET_STATUS = new Map<string, {
    icon: React.ReactNode,
    backgroundColor: string
  }>([
    ['new', {
      icon: <IconInfoSquare size={24} />,
      backgroundColor: 'gray.6'
    }],
    ['open', {
      icon: <IconInbox size={24} />,
      backgroundColor: 'blue'
    }],
    ['pending', {
      icon: <IconReport size={24} />,
      backgroundColor: 'orange'
    }],
    ['on-hold', {
      icon: <IconAlertCircle size={24} />,
      backgroundColor: 'red.6'
    }],
    ['solved', {
      icon: <IconCircleCheck size={24} />,
      backgroundColor: 'green.7'
    }],
    ['closed', {
      icon: <IconCircleX size={24} />,
      backgroundColor: 'gray.9'
    }]
  ])

  return !status ? null : (
    <Badge
      size="lg"
      color={TICKET_STATUS.get(status)?.backgroundColor}
      leftSection={
        <ActionIcon
          size="xs"
          color={TICKET_STATUS.get(status)?.backgroundColor}
          radius="xl">
          {TICKET_STATUS.get(status)?.icon}
        </ActionIcon>
      }>
      {statusDisplay}
    </Badge>
  )
}
