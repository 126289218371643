import {
  Badge,
  Box,
  createStyles,
  Group,
  Popover,
  Text,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconArrowDown, IconReceiptRefund } from '@tabler/icons'
import { MouseEvent, useState } from 'react'

interface Props {
  id: string
  children: any
  icon?: any
  isReverted: boolean
  caption?: any
  color?: string
  disableTypography?: boolean
  content?: any
  onlyIcon?: boolean
}

const useStyles = createStyles(theme => ({
  icon: { borderRadius: '50%', width: 34, height: 34 },
  paper: { padding: theme.spacing.sm, maxWidth: '340px' },
  bar: {
    width: '100%',
    height: theme.spacing.sm,
    marginTop: theme.spacing.xs
  },
  progress: {
    width: '70%',
    marginLeft: '10%',
    height: theme.spacing.sm
  }
}))

function Base(props: Props) {
  const {
    id,
    children,
    color,
    icon = null,
    isReverted = false,
    caption = null,
    content = null,
    onlyIcon = false
  } = props

  if (content && !id) throw Error('Id is required using content props')

  // Hooks
  const { classes } = useStyles()
  const theme = useMantineTheme()

  // States
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  // Constants
  const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const isLg = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`)
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)

  return (
    <Group spacing="xs" align="center">
      <Group spacing="xs" noWrap>
        {icon && (
          <Group>
            {onlyIcon && (
              <Tooltip label={children}>
                <ThemeIcon color={color} className={classes.icon}>
                  {icon}
                </ThemeIcon>
              </Tooltip>
            )}
            {!onlyIcon && (
              <ThemeIcon color={color} className={classes.icon}>
                {icon}
              </ThemeIcon>
            )}
          </Group>
        )}
        {!onlyIcon && (
          <Box>
            {caption && <Text size={
              isSm ? 'sm' : isMd ? 'md' : isLg ? 'sm' : 'md'
            }>{caption}</Text>}
            {children}
          </Box>
        )}
      </Group>
      <Badge
        color="red"
        size="lg"
        px="xs"
        hidden={!isReverted}
        leftSection={
          <ThemeIcon color="red" variant="light" size="sm" display="flex">
            <IconReceiptRefund />
          </ThemeIcon>
        }>
        Pedido estornado
      </Badge>
      {content && (
        <Group>
          <UnstyledButton
            aria-owns={open ? id : undefined}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}>
            <IconArrowDown />
          </UnstyledButton>
          <Popover id={id} opened={open} position="bottom-end" onClose={handlePopoverClose}>
            {content}
          </Popover>
        </Group>
      )}
    </Group>
  )
}

export default Base
