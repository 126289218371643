import {
  Anchor,
  Box,
  createStyles,
  Group,
  Loader,
  MultiSelect,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme
} from '@mantine/core'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { PictureCard } from '@/components/surfaces'
import { useFetch } from '@/lib/hooks'
import { stringCompare } from '@/lib/utils'
import { Seller as SellerType } from '@/types'

interface Props {
  selectedSeller?: any | null
  required?: boolean
  submitting?: boolean
  onChange?: (newValue: SellerType | null) => void
  inputProps?: any
}

const useStyles = createStyles(theme => ({
  option: {
    width: '100%',
    ':hover': { backgroundColor: theme.colors.gray[2] }
  }
}))

export default function SupportSellerAutocomplete({
  onChange,
  selectedSeller,
  required = true,
  submitting = false,
  inputProps
}: Props) {
  // Hooks
  const router = useRouter()
  const theme = useMantineTheme()
  const { classes } = useStyles()

  // Constants
  const { site: siteSlug } = router.query || {}

  // States
  const [search, setSearch] = useState('')

  // Fetch callback
  const { data, error } = useFetch([siteSlug ? `/${siteSlug}/` : null])
  const support = data?.supportConfiguration?.support
  const dataSeller = data?.seller
  const sellers = data?.supportConfiguration?.partners?.map((partner: any) => partner.seller) || []
  const results = [...sellers].filter(
    (seller, index, array) => index === array.findIndex(item => item?.uid === seller?.uid)
  )

  // Constants
  const loading = !data && !error
  const formattedData = Array.isArray(results)
    ? [...(support === 'site-and-partners' ? [dataSeller] : []), ...results]
        .map((seller: any) => ({
          ...seller,
          label: `${seller?.displayName}`,
          value: seller?.uid
        }))
        .filter(
          (seller, index, array) => index === array.findIndex(item => item?.uid === seller?.uid)
        )
    : []

  // Actions
  const handleChange = (value: string) =>
    onChange?.(formattedData.find(seller => seller?.uid === value))

  // Effects
  useEffect(() => {
    if (support === 'site' && !selectedSeller) onChange?.(dataSeller)
  }, [support, selectedSeller, dataSeller, onChange])

  // Render
  if (selectedSeller) {
    return (
      <Box p={2}>
        <Text mb="sm">
          Vendedor: <strong>{selectedSeller?.displayName}</strong>{' '}
          <Anchor
            size="sm"
            color={theme.colors[theme.primaryColor][7]}
            onClick={() => onChange?.(null)}
            hidden={data?.supportConfiguration?.support === 'site'}>
            (Alterar)
          </Anchor>
        </Text>
        <Group spacing="sm" position="apart">
          <PictureCard title={selectedSeller?.displayName} picture={selectedSeller?.logo} />
        </Group>
      </Box>
    )
  }

  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    value: string
    displayName: string
    logo: string
  }

  // eslint-disable-next-line react/display-name
  const itemComponent = React.forwardRef<HTMLDivElement, ItemProps>(
    ({ value, displayName, logo }: ItemProps, ref: any) => (
      <div ref={ref}>
        <UnstyledButton p={5} onClick={() => handleChange(value)} className={classes.option}>
          <PictureCard title={displayName} picture={logo} />
        </UnstyledButton>
      </div>
    )
  )

  return (
    <Stack spacing={0}>
      <MultiSelect
        label="Selecione o vendedor relacionado ao atendimento:"
        placeholder="Buscar Vendedor"
        data={formattedData}
        clearable
        searchable
        nothingFound={loading ? 'Buscando...' : 'Nenhum resultado'}
        searchValue={search}
        onSearchChange={setSearch}
        filter={(value, selected, item) => stringCompare(item?.label, search)}
        rightSection={loading && <Loader size="xs" />}
        onChange={handleChange}
        error={required && submitting && !selectedSeller ? 'Este campo é obrigatório' : ''}
        value={selectedSeller}
        itemComponent={itemComponent}
        {...inputProps}
      />
    </Stack>
  )
}
