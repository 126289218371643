import {
  ActionIcon,
  Box,
  Button,
  Card,
  createStyles,
  Grid,
  Group,
  Text,
  Title,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconDoorEnter } from '@tabler/icons'
import Image from 'next/image'
import Link from 'next/link'

interface Props {
  title: string
  description?: string
  detail?: string
  slug: string
  image?: string
}

const useStyles = createStyles(theme => ({
  hero: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xl * 2,
    [theme.fn.smallerThan('lg')]: { padding: theme.spacing.lg }
  },
  heroContent: {
    marginRight: theme.spacing.xl * 2,
    [theme.fn.smallerThan('md')]: { marginRight: 0 }
  },
  heroImageWrapper: {
    position: 'relative',
    width: '60%',
    height: 250,
    [theme.fn.smallerThan('md')]: { display: 'none' }
  },
  segment: {
    '&[data-active]': {
      border: 'none',
      borderColor: 'none'
    },
    button: {
      [theme.fn.smallerThan('xs')]: { padding: theme.spacing.xs / 2 }
    }
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%',
    aspectRatio: '16/9',
    backgroundColor: theme.colors[theme.primaryColor][0]
  },
  detailDisplay: {
    padding: 4,
    backgroundColor: theme.colors.gray[0]
  }
}))

export default function PageCard({ title, description, detail, slug, image }: Props) {
  // Hooks
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  // Constants
  const currentPath = window?.location?.pathname || '/'

  return (
    <Card withBorder p={0}>
      <Card.Section>
        <Box className={classes.imageWrapper} hidden={isXs}>
          <Image
            src={image || '/images/placeholders/store.png'}
            alt={title}
            fill
            quality={60}
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box className={classes.detailDisplay} hidden={!detail}>
          <Text size="xs" color="dimmed" align="right" mr="xs">
            {detail}
          </Text>
        </Box>
      </Card.Section>
      <Grid p="sm" justify="center" align="center" grow>
        <Grid.Col span={9} xs={12}>
          <Title order={isXs ? 3 : 5}>{title}</Title>
        </Grid.Col>
        <Grid.Col span={3} xs={12}>
          <Button
            component={Link}
            href={`${currentPath}/${slug}`}
            variant="light"
            fullWidth
            rightIcon={<IconDoorEnter size={16} />}
            hidden={isXs}>
            Entrar
          </Button>
          <Group position="right">
            <ActionIcon
              component={Link}
              href={`${currentPath}/${slug}`}
              variant="light"
              color="custom"
              hidden={!isXs}>
              <IconDoorEnter size={16} />
            </ActionIcon>
          </Group>
        </Grid.Col>
      </Grid>
    </Card>
  )
}
