import { Alert, Box, Text } from '@mantine/core'
import { IconLayersOff } from '@tabler/icons'
import React from 'react'

type Props = {
  icon?: React.ReactNode
  color?: string
  title?: string
  message?: string
  boxStyles?: object
}

export default function ViewError({ icon, color, title, message, boxStyles }: Props) {
  return (
    <Box
      sx={{
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        ...boxStyles
      }}>
      <Alert icon={icon || <IconLayersOff />} title={title} color={color || 'red'}>
        <Text color={color || 'red'}>{message}</Text>
      </Alert>
    </Box>
  )
}
