import { Modal, Text, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useState } from 'react'

interface Props {
  title: string
  description: string
  textSize?: string | number
  titleSize?: string | number
}

export default function TextModal({ title, description, textSize, titleSize }: Props) {
  // Hooks
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  // States
  const [opened, setOpened] = useState(false)

  return (
    <>
      <Text
        span
        variant="link"
        size={textSize || isXs ? 'sm' : 'md'}
        onClick={() => setOpened(open => !open)}
        sx={{ '&:hover': { cursor: 'pointer' } }}>
        {title}
      </Text>
      <Modal
        centered
        opened={opened}
        size="lg"
        onClose={() => setOpened(false)}
        title={<Title order={titleSize || isXs ? 6 : 5}>{title}</Title>}>
        <Text size={textSize || isXs ? 'sm' : 'md'}>{description}</Text>
      </Modal>
    </>
  )
}
