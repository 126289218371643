import { Loader, Select } from '@mantine/core'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { SWRHook } from 'swr'

import { useFetch } from '@/lib/hooks'
import { getShowcasesFromGrades, stringCompare, swrCallbackMiddleware } from '@/lib/utils'
import { Showcase as ShowcaseType } from '@/types'

type Props = {
  selected?: ShowcaseType | null
  channel?: string
  onChange?: (newValue: ShowcaseType) => void
  inputProps?: any
}

export default function ShowcaseAutocomplete({ selected, channel, onChange, inputProps }: Props) {
  // Hooks
  const router = useRouter()
  const { site: siteSlug, channel: channelSlugQuery } = router.query || {}
  const channelSlug = channelSlugQuery || channel

  // States
  const [search, setSearch] = useState('')

  // Fetch callback
  const [dataResults, setDataResults] = useState<any[]>([])
  const dataCallback = (data: any) => {
    const results = [...data, ...dataResults].filter(
      (showcase, index, array) => index === array.findIndex(item => item?.uid === showcase?.uid)
    )
    setDataResults(results)
  }

  // Fetch
  const { data, error } = useFetch(
    [
      siteSlug && channelSlug ? `/${siteSlug}/channels/${channelSlug}/grades/` : null,
      { page_size: 40, search }
    ],
    { use: [(useSWRNext: SWRHook) => swrCallbackMiddleware(dataCallback)(useSWRNext)] }
  )

  const showcases = getShowcasesFromGrades(dataResults)

  // Constants
  const loading = !!channelSlug && !data && !error
  const formattedData = Array.isArray(showcases)
    ? [...(selected ? [{ ...selected }] : []), ...showcases]
        .map((showcase: any) => ({
          ...showcase,
          label: `${showcase?.storeDisplayName} / ${showcase?.displayName} (${showcase?.cycle})`,
          value: showcase?.uid,
          group: showcase?.segmentDisplayName
        }))
        .filter(
          (showcase, index, array) => index === array.findIndex(item => item?.uid === showcase?.uid)
        )
    : []

  // Actions
  const handleChange = (value: string) =>
    onChange?.(formattedData.find(showcase => showcase?.uid === value))

  return (
    <Select
      placeholder="Selecionar série"
      data={formattedData}
      clearable
      searchable
      nothingFound={loading ? 'Buscando...' : 'Nenhum resultado'}
      searchValue={search}
      onSearchChange={setSearch}
      filter={(_, item) =>
        stringCompare(
          `${item?.segmentDisplayName} ${item?.storeDisplayName} ${item?.displayName} ${item?.cycle}`,
          search
        )
      }
      rightSection={loading && <Loader size="xs" />}
      {...inputProps}
      value={selected?.uid || null}
      onChange={handleChange}
      styles={theme => ({
        item: {
          '&[data-selected]': {
            '&, &:hover': { backgroundColor: theme.colors.blue[1], color: theme.white }
          }
        }
      })}
    />
  )
}
