import { Box, Button, Grid, Group, Loader, Select, TextInput } from '@mantine/core'
import { IconArrowBack, IconClick } from '@tabler/icons'
import { useRouter } from 'next/router'
import React, { forwardRef, useEffect, useState } from 'react'

import { PictureCard } from '@/components/surfaces'
import { useFetch } from '@/lib/hooks'
import { api, stringCompare } from '@/lib/utils'
import { Channel as ChannelType } from '@/types'

type Props = {
  selected?: ChannelType | null
  onChange?: (newValue: ChannelType) => void
  inputProps?: any
}

export default function StudentChannelAutocomplete({ selected, onChange, inputProps }: Props) {
  // Hooks
  const router = useRouter()
  const { site: siteSlug } = router.query || {}

  // States
  const [search, setSearch] = useState('')

  const [findChannel, setFindChannel] = useState(false)
  const [channelCodeValue, setChannelCodeValue] = useState('')
  const [channelCodeLoading, setChannelCodeLoading] = useState(false)
  const [channelCodeError, setChannelCodeError] = useState(false)

  // Fetch
  const { data, error } = useFetch([siteSlug ? `/${siteSlug}/students/options/` : null])

  // Constants
  const loading = (!data && !error) || channelCodeLoading

  const { allowChannelSelection } = data || {}

  const formattedData = Array.isArray(data?.channels)
    ? [...(selected ? [{ ...selected }] : []), ...data.channels]
        .map((channel: any) => ({
          ...channel,
          label: `${channel?.title}`,
          value: channel?.uid
        }))
        .filter(
          (channel, index, array) => index === array.findIndex(item => item?.uid === channel?.uid)
        )
        .sort((a, b) => b?.title - a?.title)
    : []

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, any>(
    (
      {
        uid,
        site,
        institution,
        title,
        description,
        slug,
        privacy,
        isUnlisted,
        maintenance,
        maintenanceMessage,
        logo,
        cover,
        absoluteUrl,
        displayBanner,
        ...others
      }: any,
      ref
    ) => (
      <Box p="xs" ref={ref} {...others}>
        <PictureCard title={title} picture={logo} />
      </Box>
    )
  )

  // Actions
  const handleChange = (value: string) =>
    onChange?.(formattedData.find(channel => channel?.uid === value))

  const handleCloseChannelCode = () => {
    setChannelCodeValue('')
    setChannelCodeError(false)
    setFindChannel(false)
  }

  const handleChannelCode = async () => {
    setChannelCodeError(false)
    setChannelCodeLoading(true)
    try {
      const response = await api.get(`/${siteSlug}/channels/${channelCodeValue}/`)
      if (response.status === 200) {
        onChange?.(response.data)
        handleCloseChannelCode()
      }
    } catch (error) {
      setChannelCodeError(true)
    }
    setChannelCodeLoading(false)
  }

  // Effects
  useEffect(() => {
    if (!loading && allowChannelSelection === false) onChange?.(formattedData?.[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowChannelSelection, loading])

  return findChannel ? (
    <Grid gutter="xs" align="flex-start">
      <Grid.Col span="auto">
        <TextInput
          required
          placeholder="Digite o código"
          value={channelCodeValue}
          disabled={loading}
          onChange={event => setChannelCodeValue(event.currentTarget.value)}
          error={channelCodeError ? 'Código inválido' : undefined}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              handleChannelCode()
            }
          }}
          rightSection={channelCodeLoading && <Loader size="xs" />}
        />
      </Grid.Col>
      <Grid.Col span="content">
        <Button variant="outline" size="md" disabled={loading} onClick={handleChannelCode}>
          Ok
        </Button>
      </Grid.Col>
      <Grid.Col span="content">
        <Button variant="light" size="md" disabled={loading} onClick={handleCloseChannelCode}>
          <IconArrowBack />
        </Button>
      </Grid.Col>
    </Grid>
  ) : (
    <Box>
      <Select
        placeholder="Selecionar canal"
        itemComponent={SelectItem}
        data={formattedData}
        clearable
        searchable
        nothingFound={loading ? 'Buscando...' : 'Nenhum resultado'}
        searchValue={search}
        onSearchChange={setSearch}
        filter={(_, item) => stringCompare(`${item?.title}`, search)}
        rightSection={loading && <Loader size="xs" />}
        {...inputProps}
        disabled={inputProps?.disabled || allowChannelSelection === false}
        value={selected?.uid || null}
        onChange={handleChange}
        styles={theme => ({
          item: {
            '&[data-selected]': {
              '&, &:hover': { backgroundColor: theme.colors.blue[1], color: theme.white }
            }
          }
        })}
      />
      {!!allowChannelSelection && !inputProps?.disabled && !selected && (
        <Group position="right" mt="xs">
          <Button
            size="xs"
            variant="white"
            compact
            rightIcon={<IconClick />}
            sx={{ marginLeft: 'auto', marginRight: 0 }}
            onClick={() => setFindChannel(true)}>
            Código da escola?
          </Button>
        </Group>
      )}
    </Box>
  )
}
