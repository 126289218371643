import { IconKeyOff } from '@tabler/icons'
import React from 'react'

import { ViewError } from '@/components/commons/'

export default function ViewDenied() {
  return (
    <ViewError
      title="Permissão negada"
      message="Você não possui permissão para acessar este conteúdo no momento. Entre em contato
    conosco para solicitar o acesso."
      icon={<IconKeyOff />}
    />
  )
}
