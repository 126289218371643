import { Avatar, Box, Card, createStyles, MantineNumberSize, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'

interface Props {
  avatar?: string | null
  avatarProps?: any
  padding?: MantineNumberSize
  children?: React.ReactNode
}

const useStyles = createStyles((theme, props: any) => ({
  profileBox: { width: '100%', minWidth: 200, maxWidth: 300 },
  avatar: { border: `1px solid ${theme.colors.gray[3]}`, backgroundColor: theme.colors.gray[0] },
  card: { height: `calc(100% - ${props.avatarSize / 2}px)` }
}))

export default function StudentProfileCard({
  avatar,
  avatarProps,
  padding = 'xl',
  children
}: Props) {
  // Hooks
  const theme = useMantineTheme()
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  // Constants
  const avatarSize = isXs ? 60 : isMd ? 80 : 100

  // Hooks
  const { classes } = useStyles({ avatarSize })

  return (
    <Box className={classes.profileBox}>
      <Avatar
        src={avatar}
        size={avatarSize}
        radius={avatarSize}
        mx="auto"
        style={{ zIndex: 1 }}
        className={classes.avatar}
        {...avatarProps}
      />
      <Card
        withBorder
        mt={-avatarSize / 2}
        pt={avatarSize / 2}
        p={padding}
        className={classes.card}>
        {children}
      </Card>
    </Box>
  )
}
