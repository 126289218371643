import { Badge, Box } from '@mantine/core'
import {
  IconCircleCheck,
  IconCirclePlus,
  IconCircleX,
  IconClock,
  IconShoppingCartPlus,
  IconTrash,
  IconUserMinus
} from '@tabler/icons'
import React from 'react'

import { ConciergeBasketStatusType } from '@/types/concierge'

interface Props {
  status: ConciergeBasketStatusType
  statusDisplay: string
}

export default function ConciergeBasket({ status, statusDisplay }: Props) {
  // Constants
  const CONCIERGE_BASKET_STATUS = new Map<
    string,
    { icon: React.ReactNode; backgroundColor: string }
  >([
    ['pending', { icon: <IconClock size={16} stroke={1.5} />, backgroundColor: 'yellow' }],
    ['created', { icon: <IconCirclePlus size={16} stroke={1.5} />, backgroundColor: 'indigo' }],
    [
      'purchased',
      { icon: <IconShoppingCartPlus size={16} stroke={1.5} />, backgroundColor: 'teal' }
    ],
    ['completed', { icon: <IconCircleCheck size={16} stroke={1.5} />, backgroundColor: 'green' }],
    ['discarded', { icon: <IconTrash size={16} stroke={1.5} />, backgroundColor: 'gray' }],
    ['errored', { icon: <IconCircleX size={16} stroke={1.5} />, backgroundColor: 'red' }],
    ['canceled', { icon: <IconUserMinus size={16} stroke={1.5} />, backgroundColor: 'orange' }]
  ])

  return !status ? null : (
    <Badge
      size="lg"
      color={CONCIERGE_BASKET_STATUS.get(status)?.backgroundColor}
      leftSection={<Box mt={6}>{CONCIERGE_BASKET_STATUS.get(status)?.icon}</Box>}>
      {statusDisplay}
    </Badge>
  )
}
