import { Text } from '@mantine/core'
import {
  IconCircleX,
  IconClock,
  IconHandStop,
  IconReceipt,
  IconRotateClockwise2,
  IconTruck
} from '@tabler/icons'

import Base from './Base'

export const ORDER_LINE_STATUS = {
  pending: {
    icon: <IconClock />,
    label: 'Pendente',
    color: 'orange'
  },
  handling: {
    icon: <IconHandStop />,
    label: 'Manuseio',
    color: 'grey'
  },
  invoiced: {
    icon: <IconReceipt />,
    label: 'Faturado',
    color: 'blue'
  },
  delivered: {
    icon: <IconTruck />,
    label: 'Entregue',
    color: 'green'
  },
  canceled: {
    icon: <IconCircleX />,
    label: 'Cancelado',
    color: 'red'
  },
  returned: {
    icon: <IconRotateClockwise2 />,
    label: 'Devolvido',
    color: 'orange'
  }
}

type OrderKeyType = keyof typeof ORDER_LINE_STATUS

interface SingleProps {
  status: OrderKeyType
  isReverted?: boolean
}

function OrderLine({ status, isReverted = false, ...otherProps }: SingleProps) {
  if (!status) return null

  return (
    <Base
      id={status}
      color={ORDER_LINE_STATUS[status]?.color}
      icon={ORDER_LINE_STATUS[status]?.icon}
      isReverted={isReverted}
      {...otherProps}>
      <Text color={ORDER_LINE_STATUS[status]?.color} weight="normal">
        {ORDER_LINE_STATUS[status]?.label}
      </Text>
    </Base>
  )
}

export default OrderLine
