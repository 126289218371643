import { Box, Button, createStyles, Group, Image, Modal, ThemeIcon, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconZoomIn, IconZoomOut } from '@tabler/icons'
import React, { useState } from 'react'

import { ProductImage as ProductImageType } from '@/types'

interface Props {
  image: ProductImageType
}

const useStyles = createStyles(theme => ({
  gallery: {
    cursor: 'pointer',
    position: 'relative'
  },
  galleryImage: {
    color: theme.colors.white,
    borderRadius: '50%',
    height: 30,
    opacity: '0.8',
    padding: 5,
    position: 'absolute',
    right: 10,
    top: 10,
    width: 30
  }
}))

export default function ImageZoom({ image }: Props) {
  // Hooks
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  // Constants
  const zoomDefault = 100
  const zoomRange = 10

  // States
  const [open, setOpen] = useState(false)
  const [zoom, setZoom] = useState(zoomDefault)
  const zoomOffset = ((zoom - zoomDefault) / 10) * 0.8 * 3 + 1

  // Actions
  const addZoom = () => {
    if (zoom < 300) {
      setZoom(zoom + zoomRange)
    }
  }
  const subZoom = () => {
    if (zoom > 50) {
      setZoom(zoom - zoomRange)
    }
  }

  return (
    <>
      <Box className={classes.gallery}>
        <Image
          onClick={() => setOpen(true)}
          src={image.file}
          alt={image.title}
          style={{ backgroundColor: '#FFFFFF', width: '100%' }}
        />
        <ThemeIcon color={theme.colors.gray[5]} className={classes.galleryImage}>
          <IconZoomIn />
        </ThemeIcon>
      </Box>
      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        title="Imagem"
        size={isSm ? '100%' : '70%'}
        fullScreen={isXs}
        centered>
        <Box sx={{ height: '100%', maxHeight: '75vh', overflow: 'auto' }}>
          <Image
            src={image.file}
            alt={image.title}
            style={{
              transform: `scale(${zoom}%) ${
                zoom > 100 ? `translate(${zoomOffset}%, ${zoomOffset}%)` : ''
              }`
            }}
          />
        </Box>
        <Group position="center" spacing="sm" mt="sm">
          <Button variant="white" onClick={() => addZoom()}>
            <IconZoomIn />
          </Button>
          {zoom}%
          <Button variant="white" onClick={() => subZoom()}>
            <IconZoomOut />
          </Button>
        </Group>
      </Modal>
    </>
  )
}
