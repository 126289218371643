import { Loader, LoadingOverlay } from '@mantine/core'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

type Props = {
  url: string
  callbackUrl?: string
  variant?: string
  replace?: boolean
}

export default function Redirect({ url, callbackUrl, variant, replace }: Props) {
  const router = useRouter()
  useEffect(() => {
    if (url) {
      router[replace ? 'replace' : 'push'](
        `${url}${callbackUrl ? `?callbackUrl=${callbackUrl}` : ''}`
      )
    }
  }, [router, url, callbackUrl, replace])
  return variant === 'loader' ? <Loader /> : <LoadingOverlay visible />
}
