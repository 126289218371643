import { Text } from '@mantine/core'
import {
  IconCheck,
  IconChecks,
  IconCircleX,
  IconClock,
  IconDeviceDesktopAnalytics,
  IconHandStop,
  IconIdOff,
  IconPlus,
  IconReceiptRefund,
  IconReceiptTax,
  IconRotateClockwise2,
  IconX
} from '@tabler/icons'

import Base from './Base'

export const RECURRENCE_STATUS = {
  created: { icon: <IconPlus />, label: 'Criado', color: 'blue' },
  queued: { icon: <IconHandStop />, label: 'Na Fila', color: 'grey' },
  waiting: { icon: <IconClock />, label: 'Aguardando', color: 'yellow' },
  analysis: { icon: <IconDeviceDesktopAnalytics />, label: 'Em Análise', color: 'orange' },
  authorized: { icon: <IconChecks />, label: 'Autorizado', color: 'green' },
  'partially-authorized': { icon: <IconCheck />, label: 'Parcialmente Autorizado', color: 'blue' },
  'not-authorized': { icon: <IconIdOff />, label: 'Não Autorizado', color: 'brown' },
  refunded: { icon: <IconReceiptRefund />, label: 'Reembolsado', color: 'green' },
  'partially-refunded': {
    icon: <IconReceiptTax />,
    label: 'Parcialmente Reembolsado',
    color: 'blue'
  },
  reversed: { icon: <IconRotateClockwise2 />, label: 'Revertido', color: 'orange' },
  cancelled: { icon: <IconCircleX />, label: 'Cancelado', color: 'red' },
  error: { icon: <IconX />, label: 'Erro', color: 'red' }
}

type OrderKeyType = keyof typeof RECURRENCE_STATUS

interface SingleProps {
  status: OrderKeyType
}

function Recurrence({ status, ...otherProps }: SingleProps) {
  if (!status) return null

  return (
    <Base
      id={status}
      color={RECURRENCE_STATUS[status]?.color}
      icon={RECURRENCE_STATUS[status]?.icon}
      isReverted={false}
      {...otherProps}>
      <Text color={RECURRENCE_STATUS[status]?.color} weight="normal">
        {RECURRENCE_STATUS[status]?.label}
      </Text>
    </Base>
  )
}

export default Recurrence
