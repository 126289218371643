import {
  Avatar,
  Box,
  createStyles,
  Group,
  MantineNumberSize,
  Text,
  Tooltip,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'

interface Props {
  picture?: string | null
  title: React.ReactNode
  subtitle?: React.ReactNode
  subtitlePosition?: 'before' | 'after'
  subtitleHiddenXs?: boolean
  pictureRadius?: MantineNumberSize
  disablePicture?: boolean
  isReverted?: boolean
}

const useStyles = createStyles(theme => ({
  avatar: { border: `1px solid ${theme.colors.gray[3]}`, borderRadius: '50%' },
  title: { lineHeight: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
  subtitle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
  titleTooltip: { backgroundColor: theme.colors[theme.primaryColor][7] }
}))

export default function PictureCard({
  picture,
  title,
  subtitle,
  subtitlePosition = 'after',
  subtitleHiddenXs = true,
  pictureRadius = 'xl',
  disablePicture = false,
  isReverted = false
}: Props) {
  // Hooks
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const { classes } = useStyles()

  const subtitleText = () => (
    <Text
      size="sm"
      color="dimmed"
      align={isReverted ? 'right' : 'left'}
      hidden={isXs && subtitleHiddenXs}
      className={classes.subtitle}>
      {subtitle}
    </Text>
  )

  return (
    <Group position={isReverted ? 'right' : 'left'} spacing="sm" noWrap>
      {!isReverted && (
        <Avatar
          src={picture}
          radius={pictureRadius}
          size={isXs ? 30 : 40}
          hidden={disablePicture}
          className={classes.avatar}
        />
      )}
      <Box sx={{ overflow: 'hidden' }}>
        <>
          {subtitlePosition === 'before' && subtitleText()}
          <Tooltip
            label={title}
            position="top-end"
            withArrow
            classNames={{ tooltip: classes.titleTooltip }}>
            <Text
              size={isXs ? 'sm' : 'md'}
              align={isReverted ? 'right' : 'left'}
              className={classes.title}>
              {title}
            </Text>
          </Tooltip>
          {subtitlePosition === 'after' && subtitleText()}
        </>
      </Box>
      {isReverted && (
        <Avatar
          src={picture}
          radius={pictureRadius}
          size={isXs ? 30 : 40}
          hidden={disablePicture}
        />
      )}
    </Group>
  )
}
