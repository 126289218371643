import { Alert, Box, DefaultMantineColor, Image, Stack, Title } from '@mantine/core'
import React from 'react'

import { Feature as FeatureType } from '@/types'

interface WrapperProps {
  children: React.ReactNode
  background?: DefaultMantineColor
}

export function Wrapper({ children, background }: WrapperProps) {
  return background && background !== 'unstyled' ? (
    <Alert color={background}>{children}</Alert>
  ) : (
    <>{children}</>
  )
}

interface Props {
  feature: FeatureType
}

export default function Banner({ feature }: Props) {
  const { config, content, image, title } = feature
  const { background } = config || {}

  return (
    <Wrapper background={background}>
      <Stack mb="md">
        {title && <Title order={3}>{title}</Title>}
        {image && <Image src={image} alt={title} />}
        {content && <Box dangerouslySetInnerHTML={{ __html: content }} />}
      </Stack>
    </Wrapper>
  )
}
