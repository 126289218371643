import { Box, Loader, Select, Text } from '@mantine/core'
import { useRouter } from 'next/router'
import React, { forwardRef, useState } from 'react'

import { useFetch } from '@/lib/hooks'
import { getShowcasesFromGrades, stringCompare } from '@/lib/utils'
import { Showcase as ShowcaseType } from '@/types'

type Props = {
  selected?: ShowcaseType | null
  loading?: boolean
  channel?: string
  onChange?: (newValue: ShowcaseType) => void
  inputProps?: any
}

export default function StudentShowcaseAutocomplete({
  selected,
  loading,
  channel,
  onChange,
  inputProps
}: Props) {
  // Hooks
  const router = useRouter()
  const { site: siteSlug, channel: channelSlugQuery } = router.query || {}
  const channelSlug = channelSlugQuery || channel

  // States
  const [search, setSearch] = useState('')

  // Fetch
  const { data, error } = useFetch([
    siteSlug && channelSlug ? `/${siteSlug}/channels/${channelSlug}/grades/` : null
  ])

  const showcases = getShowcasesFromGrades(data)

  // Constants
  const isLoading = (!!channelSlug && !data && !error) || loading

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, any>(
    (
      {
        uid,
        site,
        institution,
        title,
        description,
        slug,
        privacy,
        isUnlisted,
        maintenance,
        maintenanceMessage,
        logo,
        cover,
        absoluteUrl,
        displayBanner,
        storeDisplayName,
        segmentDisplayName,
        displayName,
        cycle,
        ...others
      }: any,
      ref
    ) => (
      <Box p="xs" ref={ref} {...others}>
        <Text>{`${storeDisplayName} / ${displayName} (${cycle})`}</Text>
      </Box>
    )
  )

  const formattedData = Array.isArray(showcases)
    ? [
        ...(selected
          ? [
              {
                ...selected,
                storeDisplayName: (selected as any)?.storeDisplayName || selected?.store?.displayName
              }
            ]
          : []),
        ...showcases
      ]
        .map((showcase: any) => ({
          ...showcase,
          label: `${showcase?.storeDisplayName} / ${showcase?.displayName} (${showcase?.cycle})`,
          value: showcase?.uid,
          group: showcase?.segmentDisplayName
        }))
        .filter(
          (showcase, index, array) => index === array.findIndex(item => item?.uid === showcase?.uid)
        )
    : []

  // Actions
  const handleChange = (value: string) =>
    onChange?.(formattedData.find(showcase => showcase?.uid === value))

  return (
    <Select
      placeholder="Selecionar série"
      data={formattedData}
      itemComponent={SelectItem}
      clearable
      searchable
      nothingFound={isLoading ? 'Buscando...' : 'Nenhum resultado'}
      searchValue={search}
      onSearchChange={setSearch}
      filter={(_, item) =>
        stringCompare(
          `${item?.segmentDisplayName} ${item?.storeDisplayName} ${item?.displayName} ${item?.cycle}`,
          search
        )
      }
      rightSection={isLoading && <Loader size="xs" />}
      {...inputProps}
      value={selected?.uid || null}
      onChange={handleChange}
      styles={theme => ({
        item: {
          '&[data-selected]': {
            '&, &:hover': { backgroundColor: theme.colors.blue[1], color: theme.white }
          }
        }
      })}
    />
  )
}
